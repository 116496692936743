import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GoogleToolID, GoogleToolsUrl } from './google-analytics.enum';
import { EnvironmentService } from '../environment/environment.service';
import { GAWindow } from './google-analytics.model';

declare const gtag: (event: string, eventType: string, eventValue: object) => void;
declare const fbq: (event: string, eventType: string, eventValue: object) => void;

@Injectable({
	providedIn: 'root',
})
export class GoogleAnaliticsService {
	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: string,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly environmentService: EnvironmentService,
	) {}

	initialize(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}

		this.loadGoogleAnalytics();
		this.loadGoogleTagMenager();
	}

	trackEvent(eventType: string, eventValue: object): void {
		if (!this.isGoogleAnalyticsInitialized()) {
			return;
		}

		gtag('event', eventType, eventValue);
	}

	trackEventPixel(eventName: string, params: object = {}): void {
		fbq('track', eventName, params);
	}

	resetDataLayer(): void {
		const win: GAWindow = window as unknown as GAWindow;

		if (!this.isGoogleAnalyticsInitialized() || !win.google_tag_manager) {
			return;
		}

		win.dataLayer.length = 8;
	}

	updatePermisions(marketing: boolean, preference: boolean, statistics: boolean): void {
		gtag('consent', 'update', {
			ad_user_data: marketing ? 'granted' : 'denied',
			ad_personalization: marketing ? 'granted' : 'denied',
			ad_storage: marketing ? 'granted' : 'denied',
			analytics_storage: statistics ? 'granted' : 'denied',
			functionality_storage: 'granted',
			personalization_storage: preference ? 'granted' : 'denied',
		});
	}

	loadPixelScript(): void {
		const pixelInitScript = this.document.createElement('script');
		const pixelInitNoScript = this.document.createElement('noscript');
		const pixelImg = this.document.createElement('img');
		const id = this.environmentService.environment.pixelId;

		pixelInitScript.type = 'text/javascript';
		pixelInitScript.text = `
			!(function (f, b, e, v, n, t, s) {
				if (f.fbq) return;
				n = f.fbq = function () {
					n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
				};
				if (!f._fbq) f._fbq = n;
				n.push = n;
				n.loaded = !0;
				n.version = '2.0';
				n.queue = [];
				t = b.createElement(e);
				t.async = !0;
				t.src = v;
				s = b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t, s);
			})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
			fbq('init', '${id}');
			fbq('track', 'PageView');
		`;

		pixelImg.height = 1;
		pixelImg.width = 1;
		pixelImg.style.display = 'none';
		pixelImg.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;

		pixelInitNoScript.appendChild(pixelImg);

		this.document.head.appendChild(pixelInitNoScript);
		this.document.head.appendChild(pixelInitScript);
	}

	loadFacebookSDK(): void {
		const environment = this.environmentService.environment;
		const facebookAppId = environment?.facebookAppId;

		if (!environment || !environment.facebookAppId) {
			console.error('facebookAppId not found in environment');
			return;
		}

		const fbAsyncInitScript = this.document.createElement('script');
		fbAsyncInitScript.type = 'text/javascript';
		fbAsyncInitScript.text = `
			window.fbAsyncInit = function () {
				FB.init({
					appId: '${facebookAppId}',
					xfbml: true,
					version: 'v22.0',
				});
				FB.AppEvents.logPageView();
			};
		`;

		// Ładowanie skryptu SDK Facebooka
		const fbSdkScript = this.document.createElement('script');
		fbSdkScript.id = 'facebook-jssdk';
		fbSdkScript.src = 'https://connect.facebook.net/en_US/sdk.js';

		// Dodanie skryptów do dokumentu
		this.document.head.appendChild(fbAsyncInitScript);
		this.document.head.appendChild(fbSdkScript);
	}

	private loadGoogleAnalytics(): void {
		if (this.isGoogleAnalyticsInitialized()) {
			return;
		}

		const scriptTag = this.document.createElement('script');

		scriptTag.src = `${GoogleToolsUrl.ANALYTICS}${this.environmentService.environment.googleAnalyticsToken}`;
		scriptTag.id = GoogleToolID.ANALYTICS_ID;
		scriptTag.async = true;
		this.document.head.appendChild(scriptTag);
		this.loadGoogleAnalyticsScript();
	}

	private isGoogleAnalyticsInitialized(): boolean {
		return !!this.document.head.querySelector(`[id="${GoogleToolID.ANALYTICS_ID}"]`);
	}

	private loadGoogleAnalyticsScript(): void {
		const gtagInitScript = this.document.createElement('script');

		gtagInitScript.type = 'text/javascript';
		gtagInitScript.id = GoogleToolID.GTAG_INIT_ID;
		gtagInitScript.text = `
		window.dataLayer = window.dataLayer || [];
		function gtag() {
		    dataLayer.push(arguments);
		}
		gtag('consent', 'default', {
			'ad_storage': 'denied',
			'ad_user_data': 'denied',
			'ad_personalization': 'denied',
			'analytics_storage': 'denied',
			'functionality_storage': 'granted',
			'personalization_storage': 'denied',
		  });
		gtag("js", new Date());
		gtag("config", "${this.environmentService.environment.googleAnalyticsToken}");
		`;

		this.document.head.appendChild(gtagInitScript);
	}

	private loadGoogleTagMenager(): void {
		if (this.isGoogletagMenagerInitialized()) {
			return;
		}

		const scriptTag = this.document.createElement('script');

		scriptTag.src = `${GoogleToolsUrl.TAG_MANAGER}${this.environmentService.environment.googleTagManagerToken}`;
		scriptTag.id = GoogleToolID.TAG_MANAGER_ID;
		scriptTag.onload = () => {
			this.initializeGoogleTools();
		};

		scriptTag.async = true;
		this.document.head.appendChild(scriptTag);
	}

	private isGoogletagMenagerInitialized(): boolean {
		return !!this.document.head.querySelector(`[id="${GoogleToolID.TAG_MANAGER_ID}"]`);
	}

	private initializeGoogleTools(): void {
		const win: GAWindow = window as unknown as GAWindow;
		win.dataLayer = win.dataLayer || [];
		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		const google = (...args: any[]) => {
			win.dataLayer.push(args);
		};
		google('js', new Date());
		google('config', this.environmentService.environment.googleTagManagerToken);
	}
}
